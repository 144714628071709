<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
    <div class="page-content w-100">
        <div class="row g-0">
            <div class="d-block ">
  <div class="row">
    <div class="col-9 mb-0 col-sm-9 col-lg-10">
      <p class="mypracticeMobile" style="margin-left: 20px;">Team</p>
    </div>
    <div class="col-2 col-sm-3 col-lg-2">
        <div class="my-3 text-center">
    <button  class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="GoSetting()" >Back</button>
  </div>
    </div>
  </div>
  <div class="hr-border" ></div>
</div>
</div>
<div class="px-4">
    <div class="row my-2" v-if="doctorPayload.clinic_hcp_id || mergedData.length">
      <div class="col-6 col-sm-4">
        <label >First Name</label>
      </div>
      <div class="col-1">
      </div>
     
    </div>
    <div class="row mt-2"  v-if="doctorPayload.clinic_hcp_id" >
      
      <div class="col-6 col-sm-4" >
        <input type="text"  :disabled="!doctorPayload.isEditable" :value="doctorPayload?.firstName"  autocomplete="off" class="form-control" />

    </div>
    <div class="col-1">
        <span>*</span>
      </div>
       <div class="col-1 ">
        <button type="button" @click="handleInputEditState(doctorPayload)" style="font-size: 20px" id="btn_schedule_add"
          class="btn btn_edit">
          <font-awesome-icon :icon="['fas', 'edit']" />
        </button>
      </div>
      
    </div>
    <div class="row mt-2"  v-for="(users,index) in mergedData" :key="users" >
      
      <div class="col-6 col-sm-4" >
        <input type="text"  :disabled="!users.isEditable" :value="users?.firstName"  autocomplete="off" class="form-control" />
    </div>
      <div class="col-1" >
        <div v-if="users.clinic_admin_id && users.clinic_hcp_id">*</div>
      </div>
       <div class="col-1">
        <button type="button" @click="handleInputEditState(users,index)" style="font-size: 20px" id="btn_schedule_add"
          class="btn btn_edit">
          <font-awesome-icon :icon="['fas', 'edit']" />
        </button>
      </div>
     
    </div>
    <span>* Clinic admistrator</span> 
    <div class="my-3">
<button @click="createCustomer" class="btn btn-blue-color text-white" style="border-radius: 10px;">Add new team member</button>
</div>
<div v-if="addCustomer">
<fieldset class="curve-box" >
    <legend class="subHeadingText">User</legend> 
<div class="row my-2">
   <div class="col-12 col-md-6 col-lg-4">
        <label>User type</label>
        <select class="form-control " namxe="select" id="select" v-model="userSelected">
            <option value="Clinician - NMC registered">Clinician - NMC registered</option>
            <option value="Clinician - Others">Clinician - Others</option>
            <option value="Non clinician">Non clinician</option>
        </select>
    </div>
</div>
<div class="row my-2">
       <div class="col-12 col-md-6 col-lg-4 ">
        <label>First name</label>
        <div class="form-group input-group">
        <span class="input-group-addon" v-if="userSelected === 'Clinician - NMC registered'">Dr.</span>
        <input type="text" class="form-control cursor UpdateTextAlignment" v-model="firstName" >
        </div>
      </div>
    <div class="col-12 col-md-6 col-lg-4 ">
        <label>Last name</label>
        <input type="text" class="form-control cursor UpdateTextAlignment" v-model="lastName" >
    </div>
</div>
<div class="row my-2">
        <div class="col-12 col-md-6 col-lg-4"> 
            <label>Mail ID</label>
            <input type="text" class="form-control cursor UpdateTextAlignment" v-model="userEmail">
        </div>
        <div class="col-12 col-md-6 col-lg-4">
            <label>Phone + Whatsapp</label>
            <input type="text" maxlength="10" class="form-control cursor UpdateTextAlignment" v-model="userPhone" >
        </div>
        
    </div>
    <div class="row mt-3">
        <div class="col-8">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="active" id="active" v-model="clinicAdmin" />
            <label class="form-check-label " for="activateORdeactivate">Also Clinic administrator</label>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-8">
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="checkbox" name="active" id="active" v-model="userActive" />
            <label class="form-check-label " for="activateORdeactivate">Active</label>
          </div>
        </div>
      </div>
    </fieldset>   
  <div class="clinicSubmitBtn my-3" >
    <div style="margin-right: 10px;">
        <button class="btn btn-blue-color text-white" @click="editbutton? updateUsers(): storeUsers()">  {{ this.editbutton ? "Update" : "Submit" }}</button>
    </div>
    <div style="margin-left: 10px;">
         <button class="btn btn-color text-white " @click="GoSetting()">Cancel</button>
    </div>
    </div>
</div>
    </div>
</div>
</template>
<script>
import axios from 'axios';
// import moment from 'moment';
export default {
components: {

},
data() {
    return { 
    
    firstName:'',
    lastName:'',
    userEmail:'',
    userPhone:'',
    userSelected:'Clinician - NMC registered',
    role:'',
    addCustomer:false,
    allCustomers: [],
    editbutton:false,
    updateCustormerData:null,
    publicData:[],
    doctorData:[],
    otherClinician:[],
    userActive:false,
    userPayload:{},
    usersList:[],
    doctorPayload:{},
    editable:false,
    clinicAdmin:false,
    allClinicData:[],
    usersUpdateData:null,
    ClinicianOtherMobileData: null,
    publicMobileData: null,
    doctorMobileData: null

    }
},
computed:{
    mergedData() {
      const mergeData = (doctorData, publicData,otherClinician) => {
        const mergedArray = [];

        const addProperties = (item) => ({
          firstName: item.firstName || null,
          lastName: item.lastName || null,
          email: item.email || null,
          mobile: item.mobile || null,
          role: item.role || null,
          updated_by: item.updated_by || null,
          isActive: item.isActive,
          updatedAt: item.updatedAt || null,
          createdAt: item.createdAt || null,
          id:item.id || null,
          clinic_admin_id :  item.clinic_admin_id||null,
          clinic_hcp_id : item.clinic_hcp_id || null
        });

        doctorData.forEach((item) => {
          mergedArray.push(addProperties(item));
        });

        publicData.forEach((item) => {
          mergedArray.push(addProperties(item));
        });

        otherClinician.forEach((item) => {
          mergedArray.push(addProperties(item));
        });

        return mergedArray;
      };

      return mergeData(this.doctorData, this.publicData,this.otherClinician);
    },
},
created: async function () {
    let DoctorUserId = localStorage.getItem("id");
    this.DoctorId = JSON.parse(DoctorUserId);
    // this. getCustomer();
    this.getUsers();
    this.getDoctorByID();
    this.getClinic();
},
methods: {
  handleInputEditState(value,index) {
    if(this.doctorPayload.clinic_hcp_id){

   
    this.usersUpdateData = value
    this.firstName = value.firstName
    this.lastName = value.lastName
    this.userEmail = value.email
    this.userPhone = value.mobile
    this.userActive = value.isActive
    this.userSelected = value.role == 'hcp'? this.userSelected = 'Clinician - NMC registered' : value.role == 'user' ? this.userSelected ='Non clinician' : this.userSelected ='Clinician - Others'
    this.clinicAdmin = value.clinic_hcp_id? true : false
    if(value.clinic_hcp_id){
        
this.doctorPayload.isEditable =true
    }else if(value.clinic_admin_id){
let previousData = [...this.mergedData]
  previousData[index].isEditable = true;
  this.mergedData = previousData;
    }
      this.addCustomer = true
      this.editbutton = true;
    }else{
        this.$swal('You may only view the list of team members. To get access to Add new team member, please contact your clinic administrator/s')
    }
    },
    GoSetting() {
    window.scrollTo(0, 0);
    this.$router.push(`/doctor/clinic/manage`);
  },
    getDoctorByID(){
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
     axios
    .get(`${BASE_API_URL}/hcps/${this.DoctorId}/one`)
    .then((response) => {
        this.doctorPayload=response.data
        this.doctorPayload.isEditable=false
    })
},
    updateDoctor(id,payload){
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
        .put(`${BASE_API_URL}/hcps/${id}/update`, payload)
        .then(response =>{
          this.getUsers()
            return response.data

        })
    },
    updateUser(id,payload){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
        .put(`${BASE_API_URL}/public-user/${id}/update`, payload)
        .then(response =>{
          this.getUsers()
            return response.data
        })
    },
    updateOther(id,payload){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
        .put(`${BASE_API_URL}/clinician-others/${id}/update`, payload)
        .then(response =>{
          this.getUsers()
            return response.data
        })
    },
    // async getCustomer(){
    //     const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    //     await axios
    // .get(`${BASE_API_URL}/admincustomers`)
    // .then(response => {
    //     response.data.map((data)=>{
    //     const users = JSON.stringify(data.users);
    //     JSON.parse(users)
    //       this.allCustomers.push(data)
    //       })
    // })
    // },
    async getClinic(){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        await axios
    .get(`${BASE_API_URL}/clinic-consultation`)
    .then(response => {
        this.allClinicData = response.data
    })
    },
    async getUsers(){
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        await axios
    .get(`${BASE_API_URL}/hcps`)
    .then(async response => {
        if(response.data){
            this.doctorData = response.data.filter((data)=>{return ( data.updated_by == 'clinic-admin' || data.clinic_admin_id) })
            await axios
    .get(`${BASE_API_URL}/public-user`)
    .then(async response =>{
        if(response.data){
            this.publicData=response.data.filter((data)=>{return (data.updated_by == 'clinic-admin' && data.clinic_admin_id) })
            await axios
    .get(`${BASE_API_URL}/clinician-others`)
    .then(response =>{
        if(response.data){
            this.otherClinician=response.data.filter((data)=>{return (data.updated_by == 'clinic-admin' && data.clinic_admin_id) })
        }})
        }})
        }})
    },

    createCustomer(){
        if(this.doctorPayload.clinic_hcp_id){
        this.addCustomer =true
        this.clearDatas();
        }else{
            this.$swal('You may only view the list of team members. To get access to Add new team member, please contact your clinic administrator/s')
        }
    },

    clearDatas(){
    this.firstName ='';
    this.lastName ='';
    this.userEmail = '';
    this.userPhone = '';
    this.userSelected = 'Clinician - NMC registered';
    this.userActive =false;
    this.clinicAdmin = false;
    },
   async storeUsers(){
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL

    if(this.userPhone){
    let mobilePayload = {
    mobile: this.userPhone,
    };
    axios
    .post(`${BASE_API_URL}/hcps/get-sign`, mobilePayload)
    .then((response) => {
        this.doctorMobileData = response.data
    axios
    .post(`${BASE_API_URL}/public-user/get-sign`, mobilePayload)
    .then((response) => {
        this.publicMobileData = response.data
     axios
    .post(`${BASE_API_URL}/clinician-others/get-sign`, mobilePayload)
    .then((response) => {
        response.data
        this.ClinicianOtherMobileData = response.data
if(this.doctorMobileData?.mobile == this.userPhone || this.publicMobileData?.mobile == this.userPhone  || this.ClinicianOtherMobileData?.mobile == this.userPhone){
        this.$swal('Number is registered for an admin role. Please enter a different number.')

    }else{
        this.userPayload = {
            firstName : this.firstName,
            lastName : this.lastName,
            email : this.userEmail,
            mobile : this.userPhone,
            role: this.userSelected == 'Clinician - NMC registered'? 'hcp' : this.userSelected == 'Non clinician' ? 'user' : 'clinician-others',
            updated_by:'clinic-admin',
            isActive:this.userActive,
            isClinicAdministrator: this.clinicAdmin,
            clinic_admin_id:this.doctorPayload.clinic_hcp_id,
            clinic_hcp_id :this.clinicAdmin? this.doctorPayload.clinic_hcp_id:'',
            clinic_admin_name: this.doctorPayload?.firstName,
            practiceId:[],
            doctorPatientId:[],
            Doctorconsultaion:[],
            consultation_id:[]
        }
            if(!this.firstName){
                this.$swal("Please enter the First name!");
                return;
            }
            if(!this.lastName){
                this.$swal("Please enter the Last name!");
                return;
            }
            if (!this.validMobile(this.userPhone)) {
                return false;
            }
            if (!this.userEmail) {
                this.$swal("Please enter the email ID!");
                return;
            }
            if (!this.validEmail(this.userEmail)) {
                return false;
            }
        
        if(this.userPayload.role == 'hcp'){
         axios
        .post(`${BASE_API_URL}/hcps`, this.userPayload)
    .then(async response =>{
        return response.data
        })
    }else if(this.userPayload.role == 'user'){
         axios
        .post(`${BASE_API_URL}/public-user`, this.userPayload)
        .then(async response =>{
       return response.data
        })
    }
    else if(this.userPayload.role == 'clinician-others'){
         axios
        .post(`${BASE_API_URL}/clinician-others`, this.userPayload)
        .then(async response =>{
            return response.data
          })
    }                                     
        this.clearDatas();
        // this.getCustomer();
        this.getUsers()
        this.addCustomer =false
        window.location.reload() 
     
    }
})
})
})
   }else{
    this.$swal("Please enter the mobile number!");
   }
    },
    async updateUsers(){
        if (!this.userPhone) {
                this.$swal("Please enter the mobile number!");
                return
            }
            if (!this.validMobile(this.userPhone)) {
                return false;
            }
            if (!this.userEmail) {
                this.$swal("Please enter the email ID!");
                return;
            }
            if (!this.validEmail(this.userEmail)) {
                return false;
            }
      const payload ={
      firstName : this.firstName,
      lastName : this.lastName,
      email : this.userEmail,
      mobile : this.userPhone,
      role: this.userSelected == 'Clinician - NMC registered'? 'hcp' : this.userSelected == 'Non clinician' ? 'user' : 'clinician-others',
      isActive:this.userActive,
      clinic_hcp_id :this.clinicAdmin? this.doctorPayload.clinic_hcp_id:'',
      clinic_admin_name: this.doctorPayload?.firstName,
      practiceId:[],
      doctorPatientId:[],
      Doctorconsultaion:[],
      consultation_id:[],
      }
        if(this.usersUpdateData?.role == 'hcp'){
            this.updateDoctor(this.usersUpdateData?.id,payload);

        }else if(this.usersUpdateData?.role == 'user'){
            this.updateUser(this.usersUpdateData?.id,payload)

        } else if(this.usersUpdateData?.role == 'clinician-others'){
            this.updateOther(this.usersUpdateData?.id,payload)
        }
    
        // this.getCustomer();
        this.addCustomer =false
        this.clearDatas();
        this.getDoctorByID ();
        this.getUsers();
        this.usersList = [];
        window.location.reload() 
    },
    validMobile(inputtxt){
            const mobilevalidation = /^[6-9]\d{9}$/;
            if(inputtxt.match(mobilevalidation)){
                return true
            }else{
                this.$swal("Please enter the correct mobile number!");
                return false;
            }
        },
    validEmail(inputtxt) {
        var email = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        var consecutiveSpecialChar = /[\W]{2}/;

        if (inputtxt.match(email) && !consecutiveSpecialChar.test(inputtxt)) {
            return true;
        } else {
            this.$swal("Please enter the correct email ID!");
            return false;
        }
    },
}
}
</script>
<style>
.clinicSubmitBtn{
    display: flex;
    justify-content:center;
}
.mypracticeMobile {
  margin-left: 20px;
  /* margin-bottom: 10px; */
  margin-top: 1rem;
  color: #000;
  font-weight: 550;
  font-size: 21px;
}
.consultation-select{
width: 400px;
font-size: 20px;
margin-left: auto;
margin-right: auto;
text-align: left;
margin-bottom: 5px;
-webkit-appearance: auto;
appearance:auto;
}
.uploadImgBtn{
    border: 2px solid #00979e;
}
 fieldset.curve-box {
        border: 2px solid #00979e;
        border-radius: 20px;
        padding: 15px;
        margin-top: 1.5rem;
    }

legend.subHeadingText {
    /* background: rgb(243, 242, 239); */
    padding: 4px 10px;
    /* margin: 0 auto; */
    display: block;
    float: none;
    width: auto;
    font-size: 20px;
}
.UpdateTextAlignment{
    text-align: left !important;
}
</style>